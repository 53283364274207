import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Start() {
    const [data, setData] = useState({});
    const domain = "https://testing.teanda.ru";
    const navigate = useNavigate();

    useEffect(() => {
        if (
            !window.Telegram ||
            !window.Telegram.WebApp ||
            !window.Telegram.WebApp.initData
        ) {
            return <div>Not telegram user</div>;
        }

        const initData = window.Telegram.WebApp.initData;

        fetch(domain + "/api/start", {
            method: 'get', 
            headers: {'x-telegram-init-data': initData}
        })
            .then(res => res.json())
            .then(
                (data) => {
                    setData(data);
                },
                (error) => {
                    console.log(error);
                }
            )
    }, []);

    function redirect() {
        if (data["user_registred"]) {
            return navigate("/menu");
        } else {
            return navigate("/register");
        }
    }

    return redirect();
}

export default Start;
