import logo from "./logo.svg";
import "./App.css";
import Header from "./registration/header";
import RegistrationForm from "./registration/registrationForm";
import Menu from "./menu/menuForm";
import Start from "./start/start"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Seacrh from "./search/search";

function App() {
  return (
    <div className="index">
    <Router>
      <Routes>
        <Route path="/" element={<Start />}/>
        <Route path="/register" element={<RegistrationForm />}/>
        <Route path="/edit_account" element={<RegistrationForm />}/>
        <Route path="/menu" element={<Menu />} />
        <Route path="/search" element={<Seacrh />} />
      </Routes>
    </Router>
    <Header />
    </div>
  );
}

export default App;
